/*Farben*/
/*#f5a37f orange*/
/*#fdd385 gelb*/
/*#91ad8e gruen*/
/*#b0dde7 hellblau*/
/*#7eaec1 blau*/

body {
    font-family: Tahoma, Arial, sans-serif;
    color: black;
}

table {
    color: black !important;
    font-size: 1rem !important;
}

table .btn-link {
    font-size: 1rem !important;
}

h1 {
    font-family: "Active-Regular";
    font-size: 5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid;
    margin-top: 1rem !important;
}

#edit-organisation h1, #edit-event h1, #edit-volunteer h1 {
    font-family: Tahoma, Arial, sans-serif;
    font-size: 2.5rem;
    padding-bottom: 1rem;
    border: none;
}

p,
form label,
ol li,
ul li,
.btn-link,
.card-footer a,
footer a,
.haurio-sticky-sidemenue a.nav-link {
    font-size: 1.2rem;
    line-height: 1.5;
}

#top-infos a {
    font-size: 1.2rem;
    line-height: 1;
}

.form-text {
    color: black;
}

#breadcrumb {
    background-color: #7eaec1;
}

.search-results-pagination .breadcrumb-item.active,
.breadcrumb-item a,
li.breadcrumb-item,
.breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-item.active {
    color: black !important;
    font-size: 1.1rem;
}

.search-results-pagination .page-item.disabled .page-link {
    color: #333333 !important;
}

/*Start - Cards*/

#show-meeting h2.card-header,
#show-organisation h2.card-header,
#show-volunteer h2.card-header {
    font-size: 1.4rem;
    line-height: 1.5;
}

#show-meeting .card li, #show-meeting .card table, #show-meeting .card p,
#show-organisation .card li, #show-organisation .card table, #show-organisation .card p,
#show-volunteer .card li, #show-volunteer .card table, #show-volunteer .card p {
    font-size: 1.2rem;
    line-height: 1.5;
    list-style: none;
}

.show-sidebar ul {
    padding-left: 0 !important;
}

.show-sidebar table {
    font-size: 1.2rem !important;
}

#show-meeting .card .card-header,
#show-organisation .card .card-header,
#show-volunteer .card .card-header {
    background-color: white;
}

/*dashboard*/

#dashboard .card .card-header, #dashboard .card .card-footer {
    background-color: #7eaec1;
}

#dashboard .card .card-footer {
    background-color: white;
}

#dashboard h2.card-header {
    font-size: 1.4rem;
    line-height: 1.5;
}

#dashboard .card li, #dashboard .card table, #dashboard .card p {
    font-size: 1.1rem;
    line-height: 1.5;
    list-style: none;
}

#dashboard ul {
    padding-left: 0 !important;
}

#dashboard .card .card-footer p {
    margin-bottom: 0;
}

.card-image-container {
    min-height: 200px !important;
}

/*Ende - Cards*/

.btn-outline-primary, .btn-outline-primary:hover {
    color: black;
    /*color: #00323F;*/
}

.btn-danger {
    padding: 0.7rem;
}

.btn-search, .btn-save, .accordion-button, .btn-delete, .accordion-button {
    font-size: 1.2rem;
}

.btn-search, .btn-save {
    background-color: #f5a37f;
}

.btn-delete {
    background-color: transparent;
    border: none;
    color: darkred !important;
}

.btn-search-icon {
    width: 25px;
    height: 25px;
}

.btn-more-info, .btn-intern-link {
    background-color: #91ad8e;
    color: black;
}

.btn-more-info {
    border: 1px solid black;
}

.btn-more-info, .btn-intern-link, .btn-primary, .btn-outline-dark {
    font-size: 1.2rem;
}

.btn-outline-dark:hover, .btn-outline-dark:focus {
    color: black;
    text-decoration: none;
}

.btn-intern-link:focus,
.btn-intern-link:hover,
.accordion-button:focus,
.accordion-button:hover,
.btn-more-info:focus,
.btn-more-info:hover,
.btn-save:focus,
.btn-save:hover,
.event-meetings .btn-primary:focus,
.event-meetings .btn-primary:hover,
.btn-check:focus + .btn-outline-primary,
.btn-search:focus,
.btn-search:hover,
#event-filter-accordion .btn-outline-primary:hover,
.btn-outline-dark:hover {
    box-shadow: 0 0 0 0.25rem rgb(33 37 41 / 50%);
    text-decoration: none !important;
    color: black;
}

.btn-outline-dark:hover {
    background-color: transparent;
}

.btn-more-info-icon {
    width: 25px;
    height: 25px;
}

#event-filter-accordion .btn-outline-primary,
#event-filter-accordion-people-groups .btn-outline-primary,
.event-meetings .btn-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary {
    border-color: #7eaec1 !important;
}

#event-filter-accordion .btn-outline-primary:focus,
.event-meetings .btn-primary,
.btn-check:checked + .btn-outline-primary {
    background-color: #7eaec1 !important;
    color: black;
}

.btn-outline-primary:active, .btn-outline-primary:hover {
    background-color: white !important;
}

.btn svg {
    width: 25px;
}

a, .btn-link, .haurio-sticky-sidemenue a.nav-link {
    color: #164194;
    text-decoration: none;
}

a:hover, a:focus, .btn-link:hover, .btn-link:focus, .haurio-sticky-sidemenue a.nav-link:hover {
    text-decoration: underline;
    color: #164194;
}

.user-nav .nav-link.active {
    font-weight: bolder;
    color: black;
}

main p a, main p .btn-link, .user-nav a {
    color: #164194;
    text-decoration: underline;
}

main p a:hover, main p a:focus, main p .btn-link:hover, main p .btn-link:focus, .user-nav a:hover, .user-nav a:focus {
    background-color: #f5a37f;
    color: black !important;
}

.copy-btn {
    background-color: white;
    color: black;
    font-size: 0.6rem;
}

.row {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.search-results-pagination .page-link {
    color: #164194 !important;
}

.search-results-pagination .page-item.active .page-link {
    color: white !important;
    background-color: #164194;
}

#main-nav, #main-nav a {
    color: black !important;
    font-size: 1.2rem;
}

#main-nav a.active {
    font-weight: bold;
}

@media (max-width: 991px) {
    .dropdown-menu {
        border: 0 !important;
    }
}

@media (max-width: 768px) {
    #main-nav, #main-nav a {
        font-size: 1.2rem;
    }
}

@media (max-width: 576px) {
    h1 {
        font-size: 3rem;
    }
}

#main-nav a {
    color: black !important;
}

#main-nav .navbar-toggler {
    background-color: #f29f05;
    color: black;
}

#main-nav.navbar .dropdown-item {
    font-size: 1.2rem !important;
    text-transform: none;
}

#main-nav.navbar .dropdown-item.active {
    font-weight: bolder;
    background-color: white;
}

#main-nav.navbar .dropdown-item:hover, #main-nav.navbar .dropdown-item:focus {
    background-color: white !important;
}

.edit-nav .nav-link {
    color: black;
}

.edit-nav .nav-link.active,
.edit-nav .nav-link:hover,
.edit-nav .nav-link:focus {
    color: black;
    border-bottom: 3px black solid;
}

.edit-nav .nav-link.active {
    font-weight: bold !important;
}

.edit-nav a:hover, .edit-nav a:focus {
    text-decoration: none;
}

.edit-nav .nav-link {
    margin: 1rem;
}

footer {
    background-color: #333333;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

footer, footer a {
    color: white;
}

footer a:hover, footer a:focus {
    color: white;
    text-decoration: underline;
}

footer ul {
    list-style: none;
    padding-left: 0 !important;
}

img {
    max-width: 100% !important;
}

.accordion-button:not(.collapsed) {
    color: black !important;
    background-color: #7eaec1 !important;
}

#event-image th, #event-image td {
    width: 33% !important;
}

#btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
}

.save-it svg.un-save:hover, .save-it a:focus svg.un-save, .save-it .save {
    fill: #b0dde7;
    width: 35px;
    height: 35px;
}

.save-it svg.save:hover, .save-it a:focus svg.save, .save-it .un-save {
    fill: #7eaec1;
    width: 40px;
    height: 40px;
}

@media (max-width: 992px) {
    .with-left-border-on-small {
        border-left: 1px solid #dee2e6 !important;
        padding-left: 1rem !important;
    }
}

.special-info-text {
    background-color: #b0dde7;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
    border-radius: 0px 30px;
    text-align: center;
}

.special-info-text a {
    color: black;
}

/*Start - Startseite Header*/
section.masthead {
    padding-top: 10.5rem;
    padding-bottom: 6rem;
    text-align: center;
    color: #fff;
    background-image: url("/images/ans-meer-gruppe.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
}

section.masthead .masthead-subheading {
    font-size: 1.5rem;
    font-style: italic;
    line-height: 1.5rem;
    margin-bottom: 25px;
    /*font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
}

section.masthead .masthead-heading {
    font-size: 3.25rem;
    font-weight: 700;
    line-height: 3.25rem;
    margin-bottom: 2rem;
    /*font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
}

@media (min-width: 768px) {
    section.masthead {
        padding-top: 17rem;
        padding-bottom: 12.5rem;
    }

    section.masthead .masthead-subheading {
        font-size: 2.25rem;
        font-style: italic;
        line-height: 2.25rem;
        margin-bottom: 2rem;
    }

    section.masthead .masthead-heading {
        font-size: 4.5rem;
        font-weight: 700;
        line-height: 4.5rem;
        margin-bottom: 4rem;
    }
}

.masthead-subheading, .masthead-heading {
    color: black;
}

.masthead-container {
    background: rgba(255, 255, 255, 0.7);
    padding-bottom: 1rem;
    padding-top: 1rem;
}

/*Ende - Startseite Header*/

/*Start - Startpage*/

#start-page li.list-group-item {
    border: none !important;
}

@media (min-width: 768px) {
    .social-list {
        max-width: 200px;
    }
}

@media (min-width: 768px) {
    .with-left-border-on-middle {
        border-left: 1px solid #dee2e6 !important;
        padding-left: 1rem !important;
    }
}

.start-page-event-card h3 {
    font-size: 1.3rem !important;
}

#start-page h3.card-title {
    font-size: 1.3rem !important;
}

#start-page p.card-text {
    font-size: 1.2rem !important;
}

#start-page .card a {
    text-decoration-color: black !important;
}

#subdomain-footer .bg-white p {
    color: black;
}

#subdomain-footer .bg-white a {
    color: #164194;
}

/*Ende - Startpage*/


.bg-haurio-orange {
    background-color: #f5a37f;
}

.bg-haurio-yellow {
    background-color: #fdd385;
}

.bg-haurio-green {
    background-color: #91ad8e;
}

.bg-haurio-light-blue {
    background-color: #b0dde7;
}

.bg-haurio-blue {
    background-color: #7eaec1;
}

/*Start - Sprunglink zum Hauptinhalt*/
#jump-to-main-link {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 0;
}

#jump-to-main-link:focus {
    font-size: 1.2rem;
    padding: 1.5rem;
    color: black;
    font-weight: bolder;
}

/*Ende - Sprunglink zum Hauptinhalt*/
